$mainBgColor: #FCFCFC !important;
$secondBgColor: #E1CFD1 !important;

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$primaryColor: #B18489 !important;
$primaryColorWithAlpha: #F84F4F1a !important;
$primaryColorWithAlpha2: #b184898a !important;

$redColor: #F84F4F !important;

$secondaryColor: #B5878C !important;
$secondaryColorWithAlpha: #B184891a !important;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;


.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}

.primary-bg {
    background-color: $primaryColor;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    color: white !important;
    background-color: $primaryColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}

.expand-cell-header{
    padding: 0px !important;
}

// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
}
